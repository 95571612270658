<template>
  <list-template
      :loading="loading"
      :search-config="searchConfig"
      :total="total"
      :current-page="currentPage"
      :table-data="tableData"
      :table-config="tableConfig"
      @onSearch="onSearch"
      @onChangePage="changeCurrentPage"
  ></list-template>
</template>

<script>

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      // 当前页码
      currentPage: 1,
      total: 0,
      pageCount: 0,
      searchConfig: [
      {
          tag:"datePicker",
          prop: "times",
          property:{
            type:"daterange",
            startPlaceholder:"登录开始时间",
            endPlaceholder:"登录结束时间",
            size:"small",
            style:"margin-right:20rem;",
            valueFormat:"yyyy-MM-dd"
          }
        },
        {
          tag: 'input',
          prop: "keyword",
          placeholder: "请输入账号/姓名"
        },
        {
          tag: 'input',
          prop: "pad_uniqueId",
          placeholder: "请输入设备id"
        }
      ],
      // 表格中的数据
      tableData: [],
      tableConfig: [
        {
          prop: "username",
          label: "账号"
        },
        {
          prop: "name",
          label: "姓名"
        },
        {
          prop: "pad_uniqueId",
          label: "设备id"
        },
        {
          prop: "login_ip",
          label: "登录ip"
        },
        {
          prop: "login_time",
          label: "登录时间"
        },
      ]
    }
  },
  mounted() {
    this.getLoginLog();
  },
  methods: {
    /**
     * @description 获取登录记录
     * @param page
     */
    getLoginLog(page = 1) {
      let search = Object.assign({},this.search) || {};
      let {times} = search;
      if (times){
        search.start_time = times[0];
        search.end_time = times[1];
        delete search.times;
      }
      let params = {
        page:page,
        ...search
      }
      this.loading = true;
      this.$_axios.get("/public/login-history" ,{
        logic:1,
        params
      }).then(res => {
        let {data} = res.data;
        let headers = res.headers;

        // 当前页码, 页数总和 , 每页数据量 , 总数据量
        let {page, pageCount, pageSize, total} = this.$tools.getPaginationInfo(headers);
        this.total = total;
        this.pageCount = pageCount;
        this.tableData = data;
      }).finally(()=>this.loading = false);
    },
    changeCurrentPage(e) {
      this.getLoginLog(e);
    },
    onSearch(val){
      this.search = val;
      this.$store.commit("setPage",1);
      this.getLoginLog();
    },
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 24rem 0 0 32rem;
  min-height: calc(100% - 16rem);
  /* .search{
     .el-input,.el-select{
       width: 240rem;
       margin-right: 24rem;
     }
   }*/
  .pagination {
    margin-top: 50rem;
  }


}
</style>
